const projektListe = {
    'WW': [
        'Purismus am See - Wartaweil, 2016',
        'Das Doppelhaus in Leichtbeton, sichtbar innen wie aussen mit Adaptionen aus Holz und Aluminium bildet einen sonnigen Wohnhof zum Ammersee, gerahmt durch den alten Baumbestand. Klare Materialwechsel der ineinander geschobenen Kuben aus Beton, Holz und Alu beleben die große Baumasse. Die vorgehängten Technikräume aus Alu schützen die Eingangsbereiche, konterkarieren mit der lichten Stahlkonstruktion des Carports die Massivität der 30m langen Nordfassade aus 50cm starkem Leichtbeton. Zwei Holzkuben auf den sonnigen Dachterrassen führen den Beton/Holzdialog der Fenster fort. Innen bildet dieser hölzerne Raum ein überraschendes Highlight am Ende der betonierten Raumfolge. Reduktion im Detail der Materialwechsel, versenkte Vorhangschienen und Leuchten, raumhohe Türen, rahmenlose Glastüren etc. steigern den puristischen Gesamteindruck.',
        20], 
    'Kol': [
        'Haus am Berg - Bad Kohlgrub, 2010',
        'Moderne Interpretation des traditionellen bayerischen Landhauses mit Ferienwohnung durch radikale Reduktion in Form und Material. Die Gliederung in massiven Sockel mit hölzernem Dachgeschoss wird hier durch die frappierende Glasfuge zum schwebenden Dach umgedeutet. Samtiger Leichtbeton und fein gemasertes Lärchenholz gehen eine spannende Allianz ein, einfachste Details steigern die Reduktion. Trotz großer Öffnungen mit Solarkollektor-Effekt wirkt der Raum durch die 50cm starken Betonwände als bergend schützende Hülle, beherrscht durch den spektakulären Bergblick. Mehrfach publiziert, Teil der BR-Reihe "Traumhäuser"',
        17], 
    'Fi': [
        'Yacht am Bach - Fischen am Ammersee, 1999',
        'Wohnen wie im Urlaub, ein Hausboot, tropisch eingewachsen am romantisch plätschernden Bach. Ablesbar gegliedert in 3 schlanke Bauteile - der hölzerne Servicetrakt im Norden, das mittlere bergende Betongehäuse der Wohn-/Schlafräume und südlich die luftige Holz-/Stahlkonstruktion der Terrasse - wirkt das Gebäude trotz seiner Länge von 20m sehr klar strukturiert und zierlich. Die großen Sonnenvorhänge des Holzdecks sorgen bei Bedarf für Schatten und Privatheit, lassen von Schiffsdecks und Kreuzfahrten durch tropische Gefilde träumen. Der 6m hohe immergrüne Bambus gliedert die beiden Terrassen. Durch die fast vollständig verglaste Südfassade mit riesigen Schiebetüren und -fenstern zur Terrasse dringt die flache Wintersonne tief in das Haus. Schwarze Schieferböden und massive Betonwände speichern die Wärme und lassen das Haus thermisch als großen Solarkollektor wirken.',
        15], 
    'Gerl': [
        'Raumschiff - Dießen am Ammersee, 2005',
        'Auf nur 370m² Grund sollte bei stark begrenztem Buget große Privatheit ermöglicht werden, was durch Verzicht auf Keller und Grenzbebauung erreicht wurde. Das schmale Gebäude schmiegt sich langgestreckt an die Nord- und Ostgrenze, bildet  mit der südlichen Garage eine Klammer um den Innenhof. Der Wohnbereich mit der gliedernden Feuerstelle bildet durch die rahmenlose Komplettverglasung mit dem Gartenhof eine räumliche Einheit. Das auskragende OG aus aluverkleideten Massivholzplatten schwebt silbern darüber, ein Raumschiff, das durch Reflexionen fast mit dem Himmel verschmilzt. Es balanciert auf 4 schlanken Stahlstützen, die östliche 10m lange Glasfuge ermöglicht Besonnung zu allen Tageszeiten. Im OG macht ein Fensterband den Flur zum Schiffsdeck und mündet als Raumerweiterung in der Bibliothek.',
        16], 
    'Str': [
        'Gemischtes Doppel - Wessling, 2005',
        'Es galt zwei befreundete Baupartner mit recht unterschiedlichen Vorgaben individuell zu betreuen und zu einer harmonischen Gesamtsituation zu kommen. Die schwierige Topographie des Grundstücks mit zusätzlichem Quergefälle zur geneigten Straße führte zu einer gegenläufigen Pultdachneigung der beiden Häuser mit verbindendem Flachdach. Es entsteht eine dynamische Schraubbewegung über das ganze Grundstück sowie eine starke räumliche Fassung des Gartenraumes. Der konische Grundriss der Häuser ermöglicht nicht nur eine angenehm geräumige, einladende Eingangs-/ Parksituation sondern lässt die Strassenfassaden sehr schlank erscheinen und steigert zusätzlich die Dynamik der exakt konturierten Kubatur. Das Wechselspiel der Dachneigungen und Materialien je nach Bauteil individualisiert die Häuser und bindet sie dennoch zusammen.',
        8], 
    'Sur': [
        'Schwarze Hausgruppe - Weipertshausen, 2007',
        'Der Bebauungsplan sah hier ein unproportioniertes Doppelhaus vor. Die Gemeinde konnte zum Glück überzeugt werden, dass inmitten alter Bauernhäuser zwei eigenständige Baukörper der dörflichen Struktur wesentlich besser entsprechen. Die schlanken, schwarzen Häuser fügen sich durch leichte Verdrehung und verbindende Schuppenbauten gut in den Kontext und schirmen die Gärten gegen die Umgehungsstraße ab. Das EG ist als Großraum mit angehängtem Arbeits/Gästezimmer konzipiert. Der Luftraum mit Galerie und Schreibplatz weitet das Haus zu ungeahnter Größe, trennt zudem Kinder- und Elternbereich. Die schwarz lasierte, sägerauhe Holzschalung fasst die Hauptbaukörper zu einer kraftvollen Kubatur zusammen, der Innenraum aus naturbelassenen Holzflächen leuchtet warm heraus.',
        9], 
    'Altbau': [
        'Altbau-Charme mit Wintergarten - Dießen am Ammersee, 2000',
        'Ein authentisches, in die Jahre gekommenes Landhaus aus den 20er-Jahren wurde komplett überarbeitet und mit modernen Anbauten versehen. Übermächtige, bedrückend schattige Fichten um das Haus entfernt.  Die seeseitige marode Terrasse wurde abgebrochen ersetzt durch einen  aufgeständerten 2-geschossigen Wintergarten als wohltuende Ergänzung der Altbauräume. Der südliche, strassenseitig eingegrabene Atelierraum mit Dachterrasse komplettiert die Einliegerwohnung im Souterrain. Es wurde sehr darauf geachtet, die vorhandenen Altbauelemente zu würdigen, die neuen Bauteile konsequent modern zu konzipieren und beides mit liebevollen Details zu gestalten. Die so entstandenen Alt-Neu-Kontraste lassen die Geschichte des Hauses ablesen und bereichern das architektonische Erleben.',
        11], 
    'Ton': [
        'Lokomotive - Dießen am Ammersee, 2003',
        'Mit Rücksicht auf den Bestand entwickelt sich ein 6m schmaler, jedoch 18m langer Baukörper, der nur zur Hälfte seeseitig zweigeschossig ist. Das elegant flachgeschwungene Tonnendach verbindet opulentes Raumangebot mit niedriger Traufhöhe, erreicht so ein bescheidenes, äusseres Volumen. Die Dachbegrünung gewährleistet auch im Sommer ein angenehmes Raumklima und lässt das Haus von oben mit dem Grün der Gärten verschmelzen. Innen überraschen die großen, 3,2m hohen Lofts unter dem Tonnendach, nur durch Möbelelemente gegliedert. Zusammen mit den 3 kleineren, nur 2,3m hohen Individualräumen im EG entwickelt sich eine angenehme räumliche Spannung. Alle Räume sind konsequent durch Schiebetüren getrennt und assoziieren japanische Häuser. Durch Reduktion auf das Wesentliche, dem klaren Grundriss und einfachste Details entstand ein Haus grosser Schlichtheit und Wärme.',
        18], 
    'Moos': [
        '3 Strandkörbe - Dießen am Ammersee, 2007',
        'Drei kompakte, eng gestaffelte Kuben mit verbindenden Carports definieren den südlichen Abschluss der dicht bebauten Fischerei zum Ammermoos. Der Abschwung des flachen Pultdaches bewirkt eine niedrige Traufhöhe und trägt mit den transluzenten Giebelflächen zum leichten Erscheinungsbild bei. Wo die Dachterrassen mit ihrem spektakulären Ausblick auf Berge und See an Dampferdecks denken lassen, so assoziieren die gewölbten Dächer der blendend weissen Anlage geblähte Segel auf dem nahen See. Konsequente Abstrahierung der Kubatur, akzentuiert durch die weissen Stahlprofile der Terrassen und die vorspringende filigrane Struktur der Carports geben der Anlage Eleganz und Leichtigkeit, fast Urlaubsflair. Zwischen den Häusern entstehen durch einseitige Grenzbebauung intime Höfe bzw. vielseitig nutzbare Carports.',
        15], 
    'Berlin': [
        'Himmel über Berlin, 2014',
        'Zentral und unweit des Regierungsviertels in Tiergarten-Moabit wartete ein maroder Dachstuhl aus der Gründerzeit auf den Ausbau. Eine neue pultförmige Holzkonstruktion wurde über dem alten Dach aufgestelzt, diese danach sukzessive entfernt, um witterungsgeschützt bauen zu können. Die schwierige Lage der Baustelle im 5. OG, im Hinterhof des großen bewohnten Hauses und unerreichbar durch LKWs und Kräne etc. war eine große logistische Herausforderung. Dennoch entstanden in nur 6 Monaten lichtdurchflutete Räume, in denen die geschlämmten Ziegelwände des Bestandes und die neuen Trockenbauwände blendend weiß über dem dunklen Eichenboden schweben. Die Decke öffnet sich nach Westen zur Dachterrasse weit in den Himmel über Berlin. Die östlich gelegenen Schlafräume lassen mit durchgehendem Fensterband am Morgen die Sonne quer durch die Wohnung flirren.',
        17], 
    'Fuer': [
        'Hölzerne Eleganz - Dießen am Ammersee, 2004',
        'Auf einem 750m² Hammergrundstück mit Baurecht für eingeschossige Bauweise konnte dieses Haus errichtet werden. Die Längsstellung zur Hangneigung bewirkte eine faktische Zweigeschossigkeit mit klarer Trennung von Wohn- und Elternbereich im EG und Kinderbereich mit separatem Eingang im UG. Das Haus ist somit in 2 Wohneinheiten teilbar. Die Winkelform bildet einen intimen Gartenraum, der durch die alten Bäume nach N und W gerahmt wird. Der südliche eingeschoßige Gebäudeschenkel mit Carport, Abstellraum und WC schirmt das Atrium zur Zufahrt ab und formt gleichzeitig den südlichen Eingangsvorplatz. Der Großraum des Erdgeschosses öffnet sich großzügig bis in die Kronen alter Eichen, die im Sommer einen wirkungsvollen Sonnenschutz darstellen. Ein langes Regenwasserbecken wird durch das Holzdeck überlagert, die Sonne spielt mit reizvollen Reflexen an der Decke.',
        7], 
    'Dre': [
        'Boarding House - Dresden, 1997',
        'Am Elbe-Hochufer in Heidenau bei Dresden verbindet ein konsequent modern gehaltener Kubus aus Beton und Holz zwei herrschaftlichen Villen der Gründerzeit. Zwei  übereinander gestapelte Reihen von jeweils 5 Maisonetten-Apartments bilden einen kompakten Block mit vorgestellten Betonelementen. Durch Laubengang erschlossen und über 2-geschossige Terrassen weit geöffnet in den Park wirken die Apartments wie Mini-Reihenhäuser, lassen die sonst übliche Enge vergessen.',
        4], 
    'Rott': [
        'Flotter Dreier - Dießen am Ammersee, 2010',
        'Quer und deutlich abgesenkt zur Strasse entwickelt sich die Wohnzeile in die Tiefe des idyllisch eingewachsenen Grundstücks. Mit 7,5m ungewöhnlich breit öffnen sich die Wohnräume des Erdgeschosses über rahmenlose raumhohe Verglasungen beidseitig in die Gärten. Die nach aussen durchlaufende Haustrennwand in Sichtbeton bindet Innen- und Aussenraum zu einer räumlichen Einheit von fast 200qm zusammen. Die Privatheit wird gesichert durch atriumartige Ummauerung des grünen 30qm-Entrees bzw. des intimen Gartenhofes. Das separat erschlossene, gut belichtete Souterrain mit Einliegerwohnung birgt grosses Potential, auch für berufliche Nutzung. Der vorgelagerte Carporthof befreit das Wohnumfeld von störendem Verkehr.',
        15], 
    'Gra': [
        'Bungalow wachgeküsst - Grafrath, 2011',
        'Am südlichen Rand einer kleinen Bungalow-Siedlung aus den 60er Jahren schlummerte ein kleiner Schatz. Der solide gebaute Bungalow, luxuriös mit integrierter Schwimmhalle ausgestattet, wurde einer kompletten Grundrissbereinigung unterzogen, der Pool stillgelegt und die Treppe zur Sauna entfernt. Die Nebenräume im EG wurden neu organisiert und die Küche in den Wohnbereich integriert. Er öffnet sich nun weit in beide Gartenbereiche. Es entstand ein wunderbar großzügiges Raumgefüge mit separat erschlossenem Atelier in der ehemaligen Schwimmhalle. Mit Dämmung, neuer Haustechnik, Fußboden- bzw. Wandheizung sowie Fenstern wurde das Haus energetisch auf zeitgemässen Standard gebracht.',
        12], 
    'Hech': [
        'Doppelvillen am Pilsensee - Hechendorf, 1990',
        'Auf einem großen, herrlichen Seegrundstück mit altem Baumbestand wurde ein Gemeinschaftskonzept mit 2 Doppelhäusern geplant. Dabei wurde vor allem auf eine Zonierung in private und gemeinschaftlich genutzte Zonen (Wintergarten, Spielbereich, Sauna, Boots- und Gästehaus) Wert gelegt. Pro Gebäude sind vier Wohneinheiten - zwei 165 m2-Haushälften und zwei 65 m2- Einheiten im UG - über den gemeinschaftlichen Windfang und Wintergarten miteinander verbunden. Dieser trennt zugleich die Terrassen, ermöglicht so Privatheit, aber auch die Belichtung des UG. Er trägt auch zum solaren Energiegewinn bei. Die Holzständerbauten werden durch Gasbrennwert-Thermen und eingeputzte Kapillarrohre als Wandheizung zusätzlich beheizt.',
        4], 
    'Zent': [
        'Atrium-Haus am Sonnengrund - Starnberg, 2010',
        'Die Wohn- und Schlafräume wickeln sich auf einer Ebene dreiseitig um das intime sonnige Atrium, deutlich abgewandt von der direkt benachbarten, stark befahrenen Bahnlinie. Die Hangkante wird durch den im UG eingeschobenen Büroriegel mit separatem Zugang optimal genutzt. Differenzierte Raumhöhen der unterschiedlichen Bereiche steigern die räumliche Spannung und das Raumerlebnis. Die Kinderzimmer erhalten mit der Galerie und dem Ausguck aus der Dachlaterne ein zusätzliches highlight.',
        4], 
    'Plein': [
        'Haus mit Aussicht - Hechendorf, 2017',
        'Ursprünglich um eine mächtige Roteiche - die dann doch leider fallen musste - entwickelt sich ein winkelförmiger prismatischer Baukörper, der wie ein Adlerhorst dort oben thront. Die imposante Aussicht über den Pilsensee zum Schloss Seefeld bestimmt den Entwurf des komplett in Wärmedämmbeton ausgeführten Hauses. Der langgestreckte Koch- und Essbereich erweitert sich über einen Luftraum ins Obergeschoss, die 5m hohe Verglasung holt den Himmel ins Haus. Ein betonierter Carport samt  Heizanlage verschwindet unterhalb im Hang und lässt den Blick frei schweifen. Unter dem angelagerten Wohnraum mit darüber angelegter Dachterrasse versteckt sich eine kleine Gästeeinheit. Die samtigen Sichtbetonflächen der 50cm starken Wände werden kontrastiert durch glatte Böden aus Linoleum sowie Holzelemente.',
        7], 
    'Win': [
        'Winkelzüge - Starnberg, 2018',
        'Gelegen direkt an der S-Bahnlinie wendet sich der betonierte Bau mit kristallinen Formen vom südlichen Nachbarn ab. Die so entstehende Raumbildung wird komplettiert durch das kleine, ebenfalls betonierte Gartenhaus, das wie ein Ableger wirkt - der Eisberg hat gekalbt. Die abgestufte Wohnlandschaft nimmt die Geländeformation auf und variiert so angemessen die Raumhöhen. Dies setzt sich aussen in den gestaffelten Terrassen fort. Im UG konnte eine Einliegerwohnung mit eigener Terrasse realisiert werden. Im OG gliedern die Bäder am Knickpunkt den Eltern- und Kinderbereich.  Der Entwurf legt neben der puristischen Wirkung der Betonskulptur großes Gewicht auf eine starke Raumbildung und Individualität der verschiedenen Aussenbereiche wie Garagenvorplatz, Eingangshof und japanischem Hof im Norden mit Wasserbecken.',
        15],
    'Sch': [
        'Baumhäuser - Schäftlarn, 2016',
        'Das geräumige Baufenster des Bebauungsplan sah für das stark bewaldete, relativ steile Hanggrundstück nur ein sehr großes Gebäude vor. Die Gemeinde konnte zum Glück überzeugt werden, dass zwei filigrane, quergestellte Gebäude den Hang und den herrlichen Baumbestand sehr viel besser bewahren und erleben lassen. Ein durchgehendes Sockel-geschoß verschwindet rückseitig im Hang und nimmt die Technik sowie Einliegerwohnungen auf. Darüber lassen die rundum verglasten Wohnetagen aus Stahl und Beton die romantische Waldatmosphäre hautnah erleben. Baumhäusern gleich sind die Schlafetagen in massiver Holzkonstruktion darüber gestelzt, dramatisiert durch kühne Auskragungen auf schiefen überlangen Stahlstützen. A life in the woods - in der Luxusausgabe ! H.D.Thoreau lässt dennoch grüssen...',
        5],
    'Augs': [
        'Frischzellenkur - Augsburg, 2020',
        'Das große Zweifamilienhaus aus den 70er-Jahren, unmittelbar am Siebentischwald gelegen, wurde einer umfassenden Auffrischung unterzogen: übermässig gewucherte, verschattende Thujen gefällt, der Grundriss bereinigt, Fensterbrüstungen abgesenkt, Rolladenkästen entfernt und hochformatige dänische, nach aussen öffnende Fenster vor der Wand hängend eingebaut. Die Fensterfläche wurde so ohne statische Eingriffe um gut 50% vergrössert. Der Effekt war verblüffend, das Haus atmete geradezu auf, der Muff der 70er verflogen. Alle Bäder wurden von Grund auf erneuert und mit bündig eingebauten Jura-Platten ausgekleidet. Die Fassade in Fensterstärke gedämmt und mit diesen bündig verputzt. Nur die Westfassade wurde belassen um den reizvollen Weinbewuchs zu erhalten. Der Austausch der alten Ölheizung gegen eine Wärmepumpe steht noch an.',
        9],
    'Chiem': [
        '3 Mäderlhaus - Chiemgau, 2021',
        'Der bereits genehmigte Plan des Hauses für eine alleinerziehende Mutter zweier Töchter wurde komplett neu erarbeitet. Konzeptionell ist das Haus in 4 Einheiten gegliedert : ein grosser offener Wohnbereich sowie 3 Schlafapartments, davon eines im Erdgeschoss, damit es auch als Ferienwohnung vermietet werden könnte. Der nördliche Anbau dient als Werkbereich für die handwerklichen und künstlerischen Ambitionen. Der Wohnraum war ursprünglich wegen dem weiten Blick nach Westen 2-geschossig mit Galerie und 2. Treppe geplant, wurde so aber nicht ausgeführt, um der Arbeitsgalerie mehr Abgeschlossenheit zu geben. ',
        15],
    'Ick': [
        'Weitblick unter Linde - Icking, 2021',
        'Unter der prächtigen uralten Linde öffnet sich das Haus voll dem grandiosen Bergblick nach Süden. Das Haus für eine 7-köpfige patchwork-Familie sollte später teilbar sein, lässt sich daher problemlos in zwei ungleiche Hälften teilen. Der Einbau einer dann erforderlichen 2. Treppe wurde vorbereitet. Der offene Wohn/Koch/Essbereich wird durch den großen  Luftraum zum zentralen Kommunikationsort mit internen Fenstern der Kinderzimmer. Die zunächst geplante 2-geschossige Laube, die die Terrasse zu einem weiteren "Raum" gemacht hätte wurde durch textile variable Sonnensegel ersetzt.      Sichtbetonelemente wie die skulpturale zentrale Treppe oder die tragenden Wände kontrastieren angenehm die Holzarchitektur. Die schwarze Holzschalung definiert die strenge, großflächig aufgeschnittene Kubatur,  aus der nachts die Holzfarben warm leuchten.',
        9],
    'Soeck': [
        'Sonnentrichter - Söcking, 2023',
        'Das extrem tiefe, sich verengende Grundstück wurde adäquat mit einem max. nach Norden platzierten, langgestreckten Haus beplant, welches sich sowohl im Grundriss wie im Schnitt der Sonne öffnet. Der eingeschossige, großzügig verglaste Wohnraum aus Sichtbeton wird im Anschluss an das Atrium als kompakter, schwarzer Holzbaukörper fortgesetzt, der an der nördlichen Spitze dramatisch als Turm endet. Diese Staffelung - verstärkt durch die unterschiedliche Konstruktion in Sichtbeton bzw. Holz innen wie aussen - definiert die unterschiedlichen Lebensbereiche, bewirkt zudem deren Eindeutigkeit bzw. Unabhängig-keit. Die keilförmige Konzeption besetzt primär den eher unattraktiven nördlichen Teil des Grundstücks und lässt maximalem Platz im Süden.',
        8],
    'MBO': [
        'Sanierung Industrie MBO Oppenweiler, Work in Progress',
        'Die über 60 Jahre sukzessiv gewachsene, ca. 18.000qm große Industrieanlage eines führenden Maschinenbaukonzerns aus der Druckbranche bedarf einer umfassenden energetischen und bauphysikalischen Überarbeitung, weitestgehende energetische Autarkie wird angestrebt. Grundsätzlich soll der Bestand  nach Möglichkeit weiterverwendet, gestalterisch aber dem high-tech-Anspruch der Firmenprodukte angeglichen werden. Betriebsabläufe werden optimiert und der zukünftigen Ausrichtung der Firma als smart factory angepasst, ggfs. durch teilweisen Neubau. ',
        5]
}
export default projektListe