import { Toolbar, Typography } from "@mui/material"

const NoPage = () => {
    return (
        <>
            <Toolbar />
            <Typography>No Page Found</Typography>
        </>
    )
}

export default NoPage