import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views-react-18-fix'

import { Grid, Toolbar, Box, Button, MobileStepper, Typography, useMediaQuery, Link } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'

import Testimonial from './Testimonial'
import testimonials from '../testimonials'
import projektListe from '../projektListe'

const Projekt = () => {
    const { projekt } = useParams()
    const maxSteps = projektListe[projekt][2]
    const [activeStep, setActiveStep] = useState(0)

    const pc = useMediaQuery('(min-width:1100px)')
    const tablet = useMediaQuery('(min-width:700px)')

    useEffect(() => {
        document.title = projektListe[projekt][0]
    }, [])

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    const handleNext = () => {
        setActiveStep((prev) => prev == maxSteps - 1 ? 0 : prev + 1)
    }

    const handleBack = () => {
        setActiveStep((prev) => prev == 0 ? maxSteps - 1 : prev - 1)
    }

    const handleStepChange = (step) => {
        setActiveStep(step)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowLeft') {
          handleBack()
        } else if (event.key === 'ArrowRight') {
          handleNext()
        }
    }

    const rows = []
    for (let i = 1; i <= maxSteps; i++) {
        rows.push(<div key={i}>
            {Math.abs(activeStep - i) <= 2 ? (
                <Box
                    component='img'
                    sx={{
                    display: 'block',
                    overflow: 'hidden',
                    width: '100%',
                    }}
                    src={require(`../bilder/Haus-${projekt}/${i}.jpg`)}
                    alt={i}
                />
                ) : null}
            </div>
            )
    }

    const tryRequire = () => {
        try {
            require(`../plaene/${projekt}.pdf`)
            return true
        } catch (err) {
            return false
        }
    }

    return (
        <Box sx={{width: '100%'}}>
            <Toolbar />
            <Grid container columnSpacing={2} sx={ tablet && {padding: '8px', justifyContent: 'center'}}>
                <Grid item xs={pc ? 7 : tablet ? 9 : 12}>
                    <Box sx={{ flexGrow: 1 }}>
                    <SwipeableViews
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                    >
                        {rows}
                    </SwipeableViews>
                    <MobileStepper
                        variant='text'
                        steps={maxSteps}
                        position='static'
                        activeStep={activeStep}
                        nextButton={
                        <Button size='small' onClick={handleNext}>
                            Next
                            <KeyboardArrowRight />
                        </Button>
                        }
                        backButton={
                        <Button size='small' onClick={handleBack}>
                            <KeyboardArrowLeft />
                            Back
                        </Button>
                        }
                    />
                    </Box>
                    <Box sx={{margin: '20px', paddingTop: '20px'}}>
                        <Grid container sx={{justifyContent: 'space-between', marginBottom: '20px'}}>
                            <Grid item>
                                <Typography variant='h5'>
                                    {projektListe[projekt][0]} 
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='h6'>
                                {tryRequire() && <a href={require(`../plaene/${projekt}.pdf`)} target='_blank'>Zu den Plänen</a>}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography>
                            {projektListe[projekt][1]}
                        </Typography>
                        {projekt == 'Kol' && (
                            <Box sx={{marginTop: '10px'}}>
                                <Typography>
                                    Komplette Folge "Traumhäuser" in der <Link href='https://www.ardmediathek.de/video/Y3JpZDovL2JyLmRlL3ZpZGVvL2QyNDRkNjMyLTMzZjMtNGU1MS04OTI3LTk0Nzk3MmI4YTNjNw' target='_blank'>ARD-Mediathek</Link>
                                </Typography>
                                <Typography>
                                    Auch verfügbar als <Link href='https://www.booking.com/hotel/de/bleibe-bad-kohlgrub.de.html' target='_blank'>Ferienwohnung</Link>.
                                </Typography>
                            </Box>
                        )}
                        {projekt == 'Chiem' && (
                            <Box sx={{marginTop: '10px'}}>
                                <Typography>
                                    Auch verfügbar als <Link href='https://www.booking.com/hotel/de/ferienhaus-170m2-nur-5-min-zum-chiemsee.de.html' target='_blank'>Ferienwohnung</Link>
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    {projekt in testimonials && <Testimonial projekt={projekt}/>}
                </Grid>
            </Grid>
            
        </Box>
    )
}

export default Projekt