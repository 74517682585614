import { Toolbar, Box, Typography, useMediaQuery } from "@mui/material"
import testimonials from '../testimonials'
const Testimonial = ({projekt}) => {
    const pc = useMediaQuery('(min-width:1100px)')
    const tablet = useMediaQuery('(min-width:700px)')
    return (
        <>
            <Toolbar />
            <Box sx={{width: '100%', marginBottom: '15px'}}>
                <Box sx={{textAlign:'center', maxWidth:'850px'}}>
                    <img style={{width: '100px', borderRadius: '50%'}} src={require(`../bilder/testimonial/${projekt}.jpg`)} alt={testimonials[projekt][0]}/>
                </Box>
                <Box>
                    <Typography sx={{textAlign: 'center', margin: '10px'}}>
                        {testimonials[projekt][1]}
                    </Typography>
                    <Typography sx={{textAlign: 'center'}} fontWeight='bold'>
                        {testimonials[projekt][0]}
                    </Typography>
                </Box>
            </Box>
            
        </>
    )
}

export default Testimonial