import { useEffect, useState } from 'react'
import { Toolbar, Typography, ImageList, ImageListItem, ImageListItemBar, useMediaQuery, Grid, CardMedia, Box, Modal, Button } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import Video from '../videos/silvery-moon.mp4'

const Mobiles = () => {
    const mobiles = [
        [1, 'Red Lilys 180 x 100 x 60', 'Stahldraht, Aluminium, Lack', 'Poetisch tanzende Seerosen, sehr attraktiv auch von oben, aber auch gut geeignet für Räume mit weniger Raumhöhe.'],
        [2, 'Spiderman 200 x 120 x 140', 'Stahldraht, Aluminium, Lack', 'Hauchzartes, zurückhaltendes Erscheinungsbild trotz der stattlichen Größe. Bereichert optisch ruhige Räume durch seine anmutig tanzenden, zarten Farbtupfer.'],
        [3, 'Leviathan 160 x 75 x 100', 'Stahldraht, Aluminium, Lack', 'In elegantem Bogen schwingt sich das Mobile in die Höhe, dem Licht entgegen ... Trotz seiner Größe ist bei einer Hängung über Tisch o.ä.  eine relativ geringe Raumhöhe ausreichend.'],
        [4, 'Changes 100 x 60 x 95', 'Stahldraht, Aluminium, Lack', 'Spielerisch leichte Komposition von scharfkantig eckig bis kugelrund, geeignet für kleinere, dafür hohe Räume oder über Tischen, Betten etc.'],
        [5, 'Red Wink 180 x 140 x 110', 'Aluminiumdraht, Holz, Lack', 'Großes, beeindruckendes Mobile im klassischen Calder-Stil mit sehr großer Raumpräsenz, 3mm Holz-Panels mit schmal geschliffenen Kanten, die eine subtile Plastizität der Formen hervorrufen.'],
        [6, 'Easy Fly 160 x 60', 'Stahldraht, Aluminium, Lack', ''],
        [7, 'Silvery Moon 200 x 100', 'Stahldraht, Aluminium, Lack', ''],
        [8, 'Peter Pan 150 x 100 x 80', 'Stahldraht, Aluminium, Lack', 'Klassisches Calder-Mobile mit dem zeitlos reizvollen Flair der Kunst von Miro, Arp etc. Große räumliche und farbliche Spannung.'],
        [9, 'Birds 240 x 80 x 50', 'Stahldraht, Aluminium, Lack', 'Sehr dynamische Wirkung, reagiert sowohl auf vertikale als auch horizontale Luftbewegung.']
    ]
    const pcModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'transparent',
        p: 4,
        padding: 0,
        border: 'none'
    }
    const mobileModal = {
        position: 'absolute',
        top: '30%',
        left: '5%',
        width: '90%',
    }
    const pc = useMediaQuery('(min-width:1100px)')
    const tablet = useMediaQuery('(min-width:700px)')
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(null)
    const handleOpen = (mobile) => {
        setSelected(mobile[0])
        setOpen(true)
    }
    const handleClose = () => {
        console.log('close')
        setOpen(false)
        setSelected(null)
    }
    const handleBack = () => {
        setSelected((prev) => prev == 1 ? mobiles[8][0] : mobiles[prev-2][0])
    }
    const handleNext = () => {
        setSelected((prev) => prev == 9 ? mobiles[0][0] : mobiles[prev][0])
    }
    const handleKeyDown = (event) => {
        console.log(selected)
        if(open) {
            if (event.key === 'ArrowLeft') {
                handleBack()
            } else if (event.key === 'ArrowRight') {
                handleNext()
            }
        }
    }
    useEffect(() => {
        document.title = 'Mobiles - Wolf Frey'
    }, [])
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    useEffect(() => {
        console.log('Selected: ' + selected)
    }, [selected])
    return (
        <Box sx={{width: '100%'}}>
            <Toolbar />
            <Grid container spacing={5} sx={{padding: '30px'}}>
                <Grid item xs={pc ? 7 : 12}>
                    <Typography variant='h5' fontWeight='bold' sx={{marginBottom: '10px'}}>Meine Mobiles - inspiriert durch Alexander Calder</Typography>
                    <Typography fontSize='17px'>sie sprechen seine Formensprache, atmen seinen Geist. Sie sind einzeln handwerklich gefertigt, 1 bis 2,5 m groß und ideal geeignet für großvolumige, höhere Räume. Sie bereichern sowohl moderne, kühl und reduziert gestaltete Räume als auch Altbauten, wo sie durch ihren stilistischen Kontrast sehr gut zur Geltung kommen. Gerne können auch kleinere oder größere Sonderanfertigungen Ihrem Raum angepasst und ausgeführt werden. Für Installationen im Aussenbereich arbeite ich mit einer Kunstschlosserei zusammen. Nicht nur die Farbgestaltung, auch die Materialität wie Lack, Holz, Plexiglas, Alu, Blattgold etc. kann individuell variiert werden. Jedes Stück ist ein Unikat mit hohem künstlerischen und handwerklichen Anspruch!</Typography>
                    <Typography variant='h5' fontWeight='bold' sx={{margin: '10px 0'}}>Mobiles beleben den Raum</Typography>
                    <Typography fontSize='17px'>Große Lufträume sind die ideale Bühne für raumgreifende Mobiles. Sie beleben den Raum, der durch das bewegte, tanzende Objekt auf immer wieder neue Weise erfahrbar und akzentuiert wird. "Ein Mobile ist ein Stück Poesie, das vor Lebensfeude tanzt und überrascht."</Typography>
                </Grid>
                <Grid item xs={pc ? 5 : 12}>
                    <CardMedia
                        sx={{marginTop: '20px'}}
                        controls
                        component='video'
                        image={Video}
                        autoPlay
                    />
                </Grid>
            </Grid>
            <ImageList cols={pc ? 3 : tablet ? 2 : 1} sx={{margin: '30px'}} gap={tablet ? 0 : 5}>
                {mobiles.map((mobile) => (
                    <ImageListItem key={mobile[0]} onClick={() => handleOpen(mobile)}>
                        <img
                            src={require('../bilder/Mobiles/' + mobile[0] + '.jpg')}
                            alt={mobile[1]}
                            style={{width: '100%'}}
                        />
                    <ImageListItemBar title={mobile[1]}/>
                    </ImageListItem>
                ))}
            </ImageList>
            { selected && 
                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <Box sx={pc ? pcModal : mobileModal}>
                        <Box sx={{position: 'relative'}}>
                            <img
                                src={require('../bilder/Mobiles/' + selected + '.jpg')}
                                alt={mobiles[selected-1][1]}
                                style={{width: '100%'}}
                            />
                            <Button 
                                sx={{position: 'absolute', left: '0%', top: '45%'}} 
                                onClick={handleBack}
                            >
                                <KeyboardArrowLeft sx={{fontSize: '3.5em', color:'#111'}}/>
                            </Button>
                            <Button
                                sx={{position: 'absolute', right: '0%', top: '45%'}}
                                onClick={handleNext}
                            >
                                <KeyboardArrowRight sx={{fontSize: '3.5em', color:'#111'}}/>
                            </Button>
                        </Box>
                        <Box>
                            <Typography color='white' fontSize='0.9em'>
                                {mobiles[selected-1][1]}<br/> {mobiles[selected-1][2]}
                            </Typography>
                            <Typography color='white' fontSize='0.9em'>
                                {mobiles[selected-1][3]}
                            </Typography>
                        </Box>
                    </Box>
                </Modal>
            }
        </Box>
    )
}

export default Mobiles