import { useEffect, useState } from 'react'
import { Box, Button, Grid, Link, MobileStepper, Toolbar, Typography, useMediaQuery } from '@mui/material'
import TitleImage from '../bilder/Titelbild-Website/KOL-titelbild.jpg'
import MediathekImage from '../bilder/Haus-Kol/1.jpg'
import testimonials from '../testimonials'
import Testimonial from './Testimonial'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import SwipeableViews from 'react-swipeable-views-react-18-fix'

const Home = () => {
    const pc = useMediaQuery('(min-width:1100px)')
    const tablet = useMediaQuery('(min-width:700px)')
    const [activeStep, setActiveStep] = useState(0)
    const maxSteps = Object.keys(testimonials).length
    useEffect(() => {
        document.title = 'Wolf Frey - Architekt'
    }, [])

    const backgroundImage = {
        height: '85vh',
        width: '100%',
        backgroundRepeat: 'no-repeat', 
        backgroundPosition: 'center',
        backgroundSize: 'cover', 
        backgroundImage: `url(${TitleImage})`, 
        position: 'relative',
        backgroundAttachment: 'fixed',
        marginBottom: '20px'
    }

    const imageText = {
        textAlign:'center',
        textTransform:'uppercase',
        letterSpacing:'7px',
        position:'absolute',
        top:'50%',
        width:'100%',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '2em'
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep == maxSteps - 1 ? 0 : prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep == 0 ? maxSteps - 1 : prevActiveStep - 1)
    }

    const handleStepChange = (step) => {
        setActiveStep(step)
    }
    
    const rows = []
    Object.keys(testimonials).forEach((projekt) => {
        rows.push(<Box key={projekt} sx={{alignItems: 'center'}}>
            <Testimonial projekt={projekt} />
            </Box>)
    })
    
    return (
        <>
            <Toolbar />
            <Box>
                <Box style={backgroundImage}>
                    <Typography sx={imageText}>Bauen mit Beton, Holz, Glas</Typography>
                </Box>
                {pc ? (
                <Box>
                <Grid container columnSpacing={3} sx={{direction: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Grid item xs={4}>
                        <Typography variant='subtitle1' fontWeight='bold'>Moderne Architektur - Ein Mix aus Beton, Holz und Glas</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='subtitle1' fontWeight='bold'>Traumhäuser BR Mediathek</Typography>		
                    </Grid>
                </Grid>
                <Grid container columnSpacing={3} sx={{direction: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Grid item xs={4}>
                        <Typography fontSize='1em'>Architektur, das Schaffen von Räumen für Menschen, ist letztendlich auch eine bildhauerische Arbeit im großen Maßstab. Dies mit möglichst natürlichen, massiven Materialien zu einer die Sinne anregenden Architektur zu formen ist mein Ziel. Ob felsartig kraftvoller Beton, ruppig oder samtig ausgeführt, massive Holzwände, zunderschwarzer, nur geölter Stahl mit ablesbaren Produktionsspuren, gebürsteter Kalkstein mit jahrmillionenalten Einschlüssen, feingemasertes geöltes Eichen- oder Lärchenholz, gepresster Naturasphalt oder silbern glänzendes Aluminium, immer wirkt der Zusammenklang der Naturfarben harmonisch zusammen. Der Herstellungsprozess sollte möglichst sichtbar bleiben, nicht verkleidet werden. Weite Öffnungen zur Sonne lassen die Jahreszeiten und Wetterphänomene hautnah erleben.</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Box>				
                            <Link href='https://www.ardmediathek.de/video/Y3JpZDovL2JyLmRlL3ZpZGVvL2QyNDRkNjMyLTMzZjMtNGU1MS04OTI3LTk0Nzk3MmI4YTNjNw' title='Zum Video' target='_blank' >  
                            <Box component='img' src={MediathekImage} alt='Bad Kohlgrub' sx={{width: '100%', marginTop: '10px'}} />
                            </Link>
                        </Box>
                    </Grid>	
                </Grid> 
                </Box>) : (
                    <Box sx={{margin: '10px'}}>
                        <Typography variant='subtitle1' fontWeight='bold' sx={{marginBottom: '10px'}}>Moderne Architektur - Ein Mix aus Beton, Holz und Glas</Typography>
                        <Typography fontSize='17px' sx={{marginBottom: '10px'}}>Architektur, das Schaffen von Räumen für Menschen, ist letztendlich auch eine bildhauerische Arbeit im großen Maßstab. Dies mit möglichst natürlichen, massiven Materialien zu einer die Sinne anregenden Architektur zu formen ist mein Ziel. Ob felsartig kraftvoller Beton, ruppig oder samtig ausgeführt, massive Holzwände, zunderschwarzer, nur geölter Stahl mit ablesbaren Produktionsspuren, gebürsteter Kalkstein mit jahrmillionenalten Einschlüssen, feingemasertes geöltes Eichen- oder Lärchenholz, gepresster Naturasphalt oder silbern glänzendes Aluminium, immer wirkt der Zusammenklang der Naturfarben harmonisch zusammen. Der Herstellungsprozess sollte möglichst sichtbar bleiben, nicht verkleidet werden. Weite Öffnungen zur Sonne lassen die Jahreszeiten und Wetterphänomene hautnah erleben.</Typography>
                        <Typography variant='subtitle1' fontWeight='bold'>Traumhäuser BR Mediathek</Typography>		
                        <Box>				
                            <Link href='https://www.ardmediathek.de/video/Y3JpZDovL2JyLmRlL3ZpZGVvL2QyNDRkNjMyLTMzZjMtNGU1MS04OTI3LTk0Nzk3MmI4YTNjNw' title='Zum Video' target='_blank' >  
                            <Box component='img' src={MediathekImage} alt='Bad Kohlgrub' sx={{width: '100%', marginTop: '10px'}} />
                            </Link>
                        </Box>
                    </Box>
                )
                
                }
                <Grid container columnSpacing={2} sx={{padding: '8px', justifyContent: 'center' }}>
                    <Grid item xs={tablet ? 7 : 12}>
                        <SwipeableViews
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                        >
                            {rows}
                        </SwipeableViews>
                        <MobileStepper
                            variant='dots'
                            steps={maxSteps}
                            position='static'
                            activeStep={activeStep}
                            nextButton={
                            <Button size='small' onClick={handleNext}>
                                Next
                                <KeyboardArrowRight />
                            </Button>
                            }
                            backButton={
                            <Button size='small' onClick={handleBack}>
                                <KeyboardArrowLeft />
                                Back
                            </Button>
                            }
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Home