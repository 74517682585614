const testimonials = {
    'Zent': [
        'Iris Zentgraf, Atrium-Haus am Sonnengrund, Starnberg',
        '"Wir fühlen uns pudelwohl in unserem Haus und es gibt so viele Leute, die begeistert sind von der Architektur. Im Nachhinein bewundere ich Sie sehr oft im Stillen für Ihren Entwurf. Das haben Sie einfach meisterhaft gelöst und ein richtiges Wohlfühlhaus geschaffen."'
    ],
    'Sur': [
        'Ole Suraj, Schwarze Hausgruppe, Weipertshausen',
        '"Letzten Sonntag sind wir aus Apulien kommend nach 1.340 km Autobahn nachts um halb zwei bei uns Zuhause angekommen. Am nächsten Morgen haben wir in unserem Wohnzimmer gestanden, uns angeguckt und gesagt: Ist das schön hier! – Danke noch mal Wolf."'
    ],
    'Gerl': [
        'Heike Gerl, Raumschiff, Dießen am Ammersee', 
        '"Unser Haus, ich liebe es! Ich liebe es, alle Jahreszeiten ganz nah zu erleben und durch das Lichtband die Wolken ziehen zu sehen. Auch das Potential des Hauses zu Veränderungen wahrzunehmen und den Überraschungseffekt der Besucher zu geniessen, die ausrufen: Oh, Ihr habt es aber schön hier !"'
    ],
    'Gerl2': [
        'Johannes Gerl, Raumschiff, Dießen am Ammersee', 
        '"Kreativer Entwurf, so viel Platz auf nur 370qm. Einfach genial, von der Alu-Fassade bis zum Fenster-Schlitz in der Garage mit Blick auf mein Schmuckstück."'
    ],
    'WW': [
        'Philippe Dahmen, Purismus am See, Wartaweil',
        '"Wolf Frey in seinem Element als Gestalter, Künstler und nicht zuletzt als Traumverwirklicher. Er lässt Bauwerke mit ihrer Umwelt verschmelzen, puristisch, ehrlich, formvollendet und mit großer Liebe zum Detail. Häuser als Kunstwerke. Danke, mein Freund !"'
    ],
    'WW2': [
        'Anja Mielimonka, Purismus am See, Wartaweil',
        '"Durch das viele Glas und den besonderen Grundriss hat man stets das Gefühl, draußen zu sein. Eingebettet zwischen Bäumen in der Natur erfreut man sich immer wieder an dieser grandiosen modernen Betonästhetik. Das möchte ich nie mehr missen. Wir sind jeden Tag dankbar, in diesem Haus wohnen zu dürfen."'
    ],
    'Hech': [
        'Dr. Michael Horn, Doppelvillen am Pilsensee, Hechendorf',
        '"Ein Haus, das seine Besucher auch nach 30 Jahren noch in Erstaunen und Begeisterung versetzt. Hell, offen, wandelbar und erstaunlich stabil. Holz und Glas at it`s best!"'
    ],
    'Str': [
        'Jessica + Thomas Stremel, Gemischtes Doppel, Wessling',
        '"Wir haben uns vor 15 Jahren für einen außergewöhnlichen Architekten entschieden und freuen uns noch heute darüber. Wir leben und arbeiten in diesem besonderen Haus, fühlen uns wohl und erleben jeden Tag aufs Neues die Ästhetik und die architektonischen Details, die unser „Frey-Haus“ so einzigartig machen. Die Kombination von Beton, Holz und Glas, in Verbindung mit der offenen Raumgestaltung, verleiht uns ein zeitloses Gefühl von Freiheit und Transparenz.“"'
    ],
  }
  
  export default testimonials