import { Box, Grid, Toolbar, Typography, useMediaQuery } from '@mui/material'
import TitleImage from '../bilder/Frey-1b.jpg'
import { useEffect } from 'react'

const AboutMe = () => {
    const pc = useMediaQuery('(min-width:1100px)')
    const tablet = useMediaQuery('(min-width:500px)')
    useEffect(() => {
        document.title = 'Wolf Frey'
    }, [])

    const backgroundImage = {
        height: '85vh',
        width: '100%',
        backgroundRepeat: 'no-repeat', 
        backgroundPosition: 'center',
        backgroundSize: 'cover', 
        backgroundImage: `url(${TitleImage})`, 
        position: 'relative',
        backgroundAttachment: 'fixed'
    }

    const imageText = {
        textAlign:'center',
        textTransform:'uppercase',
        letterSpacing:'7px',
        position:'absolute',
        top:'50%',
        width:'100%',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '2em'
    }

    const span = {
        fontSize: '0.6em',
        color: '#e8f380',
        borderBottom: '2px solid #e8f380',
        paddingBottom: '12px',
        lineHeight: 3,
        textShadow: '0 0 0 #999',
        fontWeight:'normal',
    }
    return (
        <>
            <Toolbar />
            <div style={backgroundImage}>
                <Typography sx={imageText}><span style={span}>Architekt</span><br/>Wolf Frey</Typography>
            </div>

            <Box sx={{textAlign: 'center', margin: '20px'}}>
                <Typography variant='h5' fontWeight='bold' sx={{marginBottom: '15px'}}>Über mich</Typography>
                <Grid container spacing={2} sx={{direction: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'left'}}>
                    <Grid item xs={pc ? 4 : 12}>
                        <Typography fontSize='17px'>Nach Gymnasium und Studium in München (Techn. Universität) bin ich sehr bald in die Selbstständigkeit gestartet. Neben verschiedensten kleineren Bauvorhaben, Lehrtätigkeit an der FH, vielen Wettbewerben und städtebaulichen Arbeiten habe ich mich zur Jahrtausendwende zur Neuorientierung entschlossen. Die Ungewissheit und Langwierigkeit vieler Projekte war nicht erfüllend. Der Umzug an den Ammersee und die Konzentration auf den individuellen Wohnhausbau haben meine Sehnsucht nach schnelleren konkreten Ergebnissen meiner Arbeit erfüllt.</Typography>
                    </Grid>
                    <Grid item xs={pc ? 4 : 12}>
                    <Typography fontSize='17px'> Keine Architektur ist so persönlich, so individuell zu konzipieren wie die privater Wohnhäuser. Sie muss perfekt passen wie ein Massanzug, zu den Nutzern, zum Grundstück, sollte up to date, dabei aber nicht modisch sein. Ich hatte fast immer das Glück, Bauherren zu finden, wo die Chemie, die Fairness und das gegenseitige Vertrauen gestimmt haben, oftmals zu langjähriger Freundschaft geführt haben. Dies gilt auch für viele regionale Handwerksbetriebe, mit denen ich seit Jahrzehnten vertrauensvoll zusammen arbeite. Hierfür bin ich sehr dankbar.</Typography>
                    </Grid>
                </Grid>
            </Box>


        </>
    )
}

export default AboutMe