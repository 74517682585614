import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from './Layout'
import Home from './pages/Home'
import Projekte from './pages/Projekte'
import Projekt from './pages/Projekt'
import Mobiles from './pages/Mobiles'
import AboutMe from './pages/AboutMe'
import Datenschutz from './pages/Datenschutz'
import Impressum from './pages/Impressum'
import NoPage from './pages/NoPage'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="projekte" element={<Projekte />} />
          <Route path="projekt/:projekt" element={<Projekt />} />
          <Route path="mobiles" element={<Mobiles />} />
          <Route path="aboutme" element={<AboutMe />} />
          <Route path="datenschutz" element={<Datenschutz />} />
          <Route path="impressum" element={<Impressum />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
