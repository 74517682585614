import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Toolbar, ImageList, ImageListItem, ImageListItemBar, useMediaQuery, Box } from '@mui/material'
import projektListe from '../projektListe'

const Projekte = () => {
    useEffect(() => {
        document.title = 'Projekte - Wolf Frey'
    }, [])

    const pc = useMediaQuery('(min-width:1100px)')
    const tablet = useMediaQuery('(min-width:500px)')

    return (
        <Box sx ={{width: '100%'}}>
            <Toolbar />
            <ImageList cols={pc ? 3 : tablet ? 2 : 1} sx={tablet ? {margin: '8px'} : {margin: 0}}>
                {Object.keys(projektListe).map((projekt) => (
                    <ImageListItem key={projekt}>
                        <Link to={`/projekt/${projekt}`}>
                            <Box
                                component='img'
                                src={require('../bilder/Haus-' + projekt + '/1.jpg')}
                                alt={projektListe[projekt][0]}
                                sx={{width: '100% '}}
                            />
                        </Link>
                        <ImageListItemBar title={projektListe[projekt][0]}/>
                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    )
}

export default Projekte