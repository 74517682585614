import {AppBar, Toolbar, Typography, Stack, Button, Box, Paper, useMediaQuery, Drawer} from '@mui/material'
import { useState } from 'react'
import { Outlet, Link } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';

function Layout () {
    const [open, setOpen] = useState(false)
    const linkStyle = {
        textDecoration: 'none',
        color: 'white'
    }
    const pc = useMediaQuery('(min-width:1100px)')
    const tablet = useMediaQuery('(min-width:700px)')
    const toggleDrawer = () => setOpen((prev) => !prev)

    return (
        <Box sx={{width: '100%'}}>
            <AppBar position='absolute'>
                <Toolbar>
                    {!tablet ? (
                    <Box>
                        <Button onClick={toggleDrawer} sx={{color: 'white', position: 'absolute', top: '23%', left: '1%'}}><MenuIcon /></Button>
                        <Drawer
                            anchor='top'
                            open={open}
                            onClose={toggleDrawer}

                        >
                            <Stack direction='column' spacing={3} sx={{backgroundColor: '#1976d2', padding: '8px 0'}}>
                                <Button onClick={toggleDrawer}>
                                    <Link to='/' style={linkStyle}>Home</Link>
                                </Button>
                                <Button onClick={toggleDrawer}>
                                    <Link to='/projekte' style={linkStyle}>Projekte</Link>
                                </Button>
                                <Button onClick={toggleDrawer}>
                                    <Link to='/mobiles' style={linkStyle}>Mobiles</Link>
                                </Button>
                                <Button onClick={toggleDrawer}>
                                    <Link to='/aboutme' style={linkStyle}>Über Mich</Link>
                                </Button>
                                <Button onClick={toggleDrawer}>
                                    <Link to='/impressum' style={linkStyle}>Impressum</Link>
                                </Button>
                            </Stack>
                        </Drawer>
                    </Box>) : (
                    <Stack direction={tablet ? 'row' : 'column'} spacing={3}>
                        <Typography variant='h6'><Link to='/' style={linkStyle}>WOLF FREY</Link></Typography>
                        <Box sx={{position: 'absolute', right: '2%'}}>
                            <Button>
                                <Link to='/' style={linkStyle}>Home</Link>
                            </Button>
                            <Button sx={{marginLeft: '10px'}}>
                                <Link to='/projekte' style={linkStyle}>Projekte</Link>
                            </Button>
                            <Button sx={{marginLeft: '10px'}}>
                                <Link to='/mobiles' style={linkStyle}>Mobiles</Link>
                            </Button>
                            <Button sx={{marginLeft: '10px'}}>
                                <Link to='/aboutme' style={linkStyle}>Über Mich</Link>
                            </Button>
                            <Button sx={{marginLeft: '10px'}}>
                                <Link to='/impressum' style={linkStyle}>Impressum</Link>
                            </Button>
                        </Box>
                    </Stack>)}
                </Toolbar>
            </AppBar>

            
            <Outlet />

            <Paper sx={{position: 'static', bottom: 0, backgroundColor: '#bdbdbd', color: 'white', textAlign: 'center', padding: '10px'}}>
                    <p>Wolf Frey - Architekt<br/>
                        Oberer Albaner Weg 17a<br/>
                        86911 Dießen am Ammersee<br/>
                        T: +49 176 9649 2712<br/>
                        info@wolf-frey-architekt.de<br/>
                    </p>
                    <Link to='/datenschutz'>Datenschutz</Link>
            </Paper>
        </Box>
    )
}

export default Layout